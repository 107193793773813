import { createContext, ReactNode, useContext, useState } from 'react'

type HostedContentRoomAppBarContextType = {
  leftSlot: ReactNode,
  middleSlot: ReactNode,
  rightSlot: ReactNode,
  setLeftSlot: (node: ReactNode) => void
  setRightSlot: (node: ReactNode) => void
  setMiddleSlot: (node: ReactNode) => void
}

const HostedContentRoomAppBarContext = createContext<HostedContentRoomAppBarContextType>({} as HostedContentRoomAppBarContextType)

export const HostedContentRoomAppBarProvider = ({ children }: {children: ReactNode }) => {
  const [leftSlot, setLeftSlot] = useState<ReactNode>(<></>)
  const [middleSlot, setMiddleSlot] = useState<ReactNode>(<></>)
  const [rightSlot, setRightSlot] = useState<ReactNode>(<></>)
  return (
    <HostedContentRoomAppBarContext.Provider value={{
      leftSlot, setLeftSlot,
      middleSlot, setMiddleSlot,
      rightSlot, setRightSlot
    }}>
      {children}
    </HostedContentRoomAppBarContext.Provider>
  )
}


export function useHostedContentRoomAppBarContext() {
  return useContext(HostedContentRoomAppBarContext)
}