export type GetContentRoomsResponse = {
  rooms: ContentRoom[]
}

export type GetContentRoomResponse = ContentRoom
export type CreateContentRoomResponse = ContentRoom
export type UpdateContentRoomResponse = ContentRoom

export type ContentRoom = {
  contentRoomId: string,
  createdById: string,
  name: string,
  shortCode: string,
  expiresAt: Date | null,
  includedCategoryValueIds: string[],
  excludedCategoryValueIds: string[]
}

export type CreateContentRoomRequest = {
  name: string,
  password: string | null,
  expiresAt: Date | null,
  includedCategoryValueIds: string[],
  excludedCategoryValueIds: string[]
}

export type UpdateContentRoomRequest = {
  name: string,
  passwordUpdated: boolean,
  password: string | null,
  expiresAt: Date | null,
  includedCategoryValueIds: string[],
  excludedCategoryValueIds: string[]
}

export enum ContentRoomType {
  FULL = 1,
  EXTERNAL_SHARE = 2
}