import { HostedContentRoomAsset } from '../../../../@types/hosted-content-room.ts'
import { Box, Stack, Typography } from '@mui/material'
import { PoweredByMasset } from '../../PoweredByMasset.tsx'
import { DocumentPreviewItem } from './DocumentPreviewItem.tsx'
import { AssetPreview } from '../../../../@types/asset.ts'
import { ConsumeButton } from './ConsumeButton.tsx'
import {
  HostedContentRoomAppBarLeftSlot,
  HostedContentRoomAppBarRightSlot,
} from '../../HostedContentRoomAppBarSlots.tsx'
import { CompanyLogo } from '../../CompanyLogo.tsx'
import { useHostedContentRoomContext } from '../../../../contexts/HostedContentRoomContext.tsx'

export function DefaultAssetSinglePageDisplay({ asset, preview }: {
  asset: HostedContentRoomAsset,
  preview: AssetPreview
}) {
  const { room } = useHostedContentRoomContext()
  return (
    <Stack>
      <HostedContentRoomAppBarLeftSlot>
        <Stack direction='row' spacing={1} alignItems='center'>
          <CompanyLogo settings={room.customization.logo} />
          <Typography variant='standard'>{asset.name}</Typography>
        </Stack>
      </HostedContentRoomAppBarLeftSlot>

      <HostedContentRoomAppBarRightSlot>
        <ConsumeButton asset={asset} />
      </HostedContentRoomAppBarRightSlot>

      <Box sx={{ display: 'flex' }}>

        <Box
          component='main'
          // this matches the header height
          mt={8}
          p={8}
          sx={{
            flexGrow: 1,
            minHeight: 'calc(100vh - 64px)',
            display: 'flex',
            flexDirection: 'column',
          }}
        >

          <Stack spacing={10} sx={{ flex: 1 }}>
            <DocumentPreviewItem
              asset={asset}
              preview={preview}
            />
          </Stack>

          <Box mt={6} sx={{ maxWidth: 450 }}>
            <PoweredByMasset />
          </Box>

        </Box>
      </Box>
    </Stack>
  )
}