import { styled } from '@mui/material/styles'
import { Box, Collapse, Link, Stack, TableCell, Typography } from '@mui/material'
import { fNumber } from '../../../utils/formatNumber'
import { ReactNode, useState } from 'react'
import { StripedTableRow } from '../common/StripedTableRow'
import Iconify from '../../Iconify'
import { AnalyticsShareResult } from '../../../@types/analytics/analytics-share.ts'
import { NumberTableCell } from '../common/NumberTableCell.tsx'
import { fShortDate, fTime } from '../../../utils/formatTime.ts'
import { ContentRoomType } from '../../../@types/content-room.ts'
import Image from '../../Image.tsx'
import { srcForPreviewItem } from '../../asset-search/images.tsx'
import { AssetPreviewType } from '../../../@types/asset.ts'
import { Link as RouterLink } from 'react-router-dom'

export function ShareAnalyticsTableRow({ share, rowNum }: { share: AnalyticsShareResult, rowNum: number }) {
  // hooks
  const [expanded, setExpanded] = useState<boolean>(false)

  // handlers
  const handleExpand = () => setExpanded(!expanded)

  // calculated
  const isStriped = rowNum % 2 == 0
  const iconRotation = expanded ? '90deg' : '0deg'
  const icon = share.contentRoomType == ContentRoomType.EXTERNAL_SHARE ? 'ph:file' : 'ph:files'
  const imgSrc = srcForPreviewItem(share.externalShareAssetPreviewId, AssetPreviewType.THUMBNAIL_SM)

  return (
    <>
      <StripedTableRow isStriped={isStriped}>
        <TableCell sx={{ py: 1.5 }}>
          <Stack direction='row' spacing={2} alignItems='center' sx={{ height: 48 }}>
            <Iconify
              onClick={handleExpand}
              icon='eva:chevron-right-fill'
              sx={{
                fontSize: 20,
                transition: '100ms all ease-in-out',
                rotate: iconRotation,
                cursor: 'pointer',
              }}
            />
            <Iconify icon={icon} sx={{ height: 32, width: 32 }} />
            <Typography variant='standard' component='div'>{share.name}</Typography>
          </Stack>
        </TableCell>

        <TableCell>
          {share.contentRoomType == ContentRoomType.EXTERNAL_SHARE && (
            <ConditionalAssetLink assetId={share.externalShareAssetId}>
              <Stack direction='row' spacing={2} alignItems='center'>
                <PreviewContainer>
                  <Image alt={share.externalShareAssetName || ''} src={imgSrc} ratio='1/1' backgroundType='contain' />
                </PreviewContainer>
                <Typography variant='small'>{share.externalShareAssetName || 'Unknown'}</Typography>
              </Stack>
            </ConditionalAssetLink>
          )}
          {share.contentRoomType == ContentRoomType.FULL &&
            <Typography variant='small' component={CompactTagBox}>Multiple w/ Exploration</Typography>
          }
        </TableCell>

        <TableCell>
          <Stack direction='column'>
            <Typography variant='smallHighlight'>{fTime(share.createdAt)}</Typography>
            <Typography variant='small'>{fShortDate(share.createdAt)}</Typography>
          </Stack>
        </TableCell>

        <TableCell>
          {share.createdByName || 'Unknown'}
        </TableCell>

        <NumberTableCell>
          <Typography variant='small'>{fNumber(share.numDownloads)}</Typography>
        </NumberTableCell>

        <NumberTableCell>
          <Typography variant='small'>{fNumber(share.numViews)}</Typography>
        </NumberTableCell>
      </StripedTableRow>

      {
        expanded && (
          <StripedTableRow isStriped={isStriped}>
            <TableCell colSpan={7}>
              <Collapse in={expanded} timeout='auto' unmountOnExit>
                todo!
                {/*<UserAnalyticsTableRowDetails user={user} />*/}
              </Collapse>
            </TableCell>
          </StripedTableRow>
        )
      }
    </>

  )
}


const CompactTagBox = styled(Box)(({ theme }) => {
  const isLight = theme.palette.mode === 'light'
  const borderColor = isLight ? 'transparent' : theme.palette.grey[500_32]
  return {
    borderRadius: theme.spacing(1),
    padding: '1px 6px',
    border: `1px solid ${borderColor}`,
    backgroundColor: 'transparent',
    display: 'inline-block',
  }
})

const PreviewContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? theme.palette.bg.warmGreyLight : theme.palette.background.neutral,
  borderRadius: 4,
  overflow: 'hidden',
  height: 24,
  width: 24,
}))

const ConditionalAssetLink = ({ assetId, children }: { assetId: string | null, children: ReactNode }) => {
  const isDisabled = !Boolean(assetId)
  return isDisabled ? (<>{ children }</>) : (
    <Link
      component={RouterLink}
      to={`/assets/detail/${assetId}`}
      target='_blank'
      color='inherit'
      underline='none'
    >{children}</Link>
  )
}