import { useHostedContentRoomAppBarContext } from '../../contexts/HostedContentRoomAppBarContext.tsx'
import { ReactNode, useEffect } from 'react'

export function HostedContentRoomAppBarLeftSlot({children} : {children: ReactNode}) {
  const { setLeftSlot } = useHostedContentRoomAppBarContext()
  useEffect(() => setLeftSlot(children), [children])
  return <></>
}

export function HostedContentRoomAppBarRightSlot({children} : {children: ReactNode}) {
  const { setRightSlot } = useHostedContentRoomAppBarContext()
  useEffect(() => setRightSlot(children), [children])
  return <></>
}

export function HostedContentRoomAppBarMiddleSlot({children} : {children: ReactNode}) {
  const { setMiddleSlot } = useHostedContentRoomAppBarContext()
  useEffect(() => setMiddleSlot(children), [children])
  return <></>
}