import { HostedContentRoomAsset } from '../../../../@types/hosted-content-room.ts'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import { AssetPreview } from '../../../../@types/asset.ts'
import Iconify from '../../../Iconify.tsx'
import { useState } from 'react'
import { ActivePreviewProvider } from './context/ActivePreviewContext.tsx'
import { DocumentPreviewSection } from './DocumentPreviewSection.tsx'
import { SidebarDrawer } from './SidebarDrawer.tsx'
import { ConsumeButton } from './ConsumeButton.tsx'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import {
  HostedContentRoomAppBarLeftSlot,
  HostedContentRoomAppBarRightSlot,
} from '../../HostedContentRoomAppBarSlots.tsx'
import { CompanyLogo } from '../../CompanyLogo.tsx'
import { useHostedContentRoomContext } from '../../../../contexts/HostedContentRoomContext.tsx'

export function DefaultAssetMultiPageDisplay({ asset, previews }: {
  asset: HostedContentRoomAsset,
  previews: AssetPreview[]
}) {
  const theme = useTheme()
  const { room } = useHostedContentRoomContext()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = useState<boolean>(!isSmallScreen)

  // handlers
  const handleDrawerToggle = () => setDrawerOpen((prev) => !prev)

  // calculated props
  const renderDrawer = drawerOpen && !isSmallScreen
  return (
    <Stack>
      <HostedContentRoomAppBarLeftSlot>
        <Stack direction="row" spacing={1} alignItems="center">
          {
            !isSmallScreen && (
              <IconButton
                onClick={handleDrawerToggle}
                sx={{ ml: -1 }}
              >
                <Iconify icon='ph:list' />
              </IconButton>
            )
          }
          <CompanyLogo settings={room.customization.logo} />
          <Typography variant='standard'>{asset.name}</Typography>
        </Stack>
      </HostedContentRoomAppBarLeftSlot>

      <HostedContentRoomAppBarRightSlot>
        <ConsumeButton asset={asset} />
      </HostedContentRoomAppBarRightSlot>

      <ActivePreviewProvider>
        <Box sx={{ display: 'flex' }}>
          <SidebarDrawer open={renderDrawer} asset={asset} previews={previews} />
          <DocumentPreviewSection asset={asset} previews={previews} />
        </Box>
      </ActivePreviewProvider>
    </Stack>
  )
}