import { Box, Drawer } from '@mui/material'
import { SidebarPreviewSection } from './SidebarPreviewSection.tsx'
import { useActivePreviewContext } from './context/ActivePreviewContext.tsx'
import { HostedContentRoomAsset } from '../../../../@types/hosted-content-room.ts'
import { AssetPreview } from '../../../../@types/asset.ts'

type SidebarDrawerProps = {
  open: boolean,
  asset: HostedContentRoomAsset;
  previews: AssetPreview[];
}


export function SidebarDrawer({ open, asset, previews} : SidebarDrawerProps){
  const { leftSectionRef } = useActivePreviewContext()
  const drawerWidth = open ? 200 : 0

  return (
    <Drawer
      variant='persistent'
      anchor='left'
      open={open}
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      PaperProps={{ ref: leftSectionRef }}
    >
      <Box mt={8}>
        <SidebarPreviewSection asset={asset} previews={previews} />
      </Box>
    </Drawer>
  )
}