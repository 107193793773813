import { AppBar, Box, Link, Stack, Toolbar, Typography } from '@mui/material'
import { useHostedContentRoomAppBarContext } from '../../contexts/HostedContentRoomAppBarContext.tsx'
import { useTheme } from '@mui/material/styles'

export function HostedContentRoomAppBar() {
  const { leftSlot, middleSlot, rightSlot } = useHostedContentRoomAppBarContext()
  return (
    <AppBar
      position='fixed'
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <Toolbar
        sx={{ height: 1 }}
      >
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          sx={{ width: '100%' }}
        >
          <Box>
            {leftSlot}
          </Box>
          <Box textAlign='center'>
            {middleSlot}
          </Box>
          <Stack direction='row' spacing={1} alignItems='center'>
            {rightSlot}
            <PoweredByMassetTag />
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  )
}

function PoweredByMassetTag() {
  const theme = useTheme()
  return (
    <Link href='https://www.getmasset.com' target='_blank' color="inherit" underline="none">
      <Stack
        direction='row'
        spacing={1}
        alignItems='center'
        sx={{
          border: `1px solid ${theme.palette.grey[500_32]}`,
          borderRadius: .5,
          paddingY: .75,
          paddingX: 1,
        }}>
        <Typography variant='small' component='span' sx={{ whiteSpace: "nowrap" }}>Powered by</Typography>
        <Box
          sx={{
            width: 18,
            height: 18,
            borderRadius: '20%',
            display: 'inline-block',
            ml: 1,
            verticalAlign: 'middle',
            marginTop: -.5,
          }}
          component='img'
          src='/assets/icons/logos/masset_slack_logo_512x512.jpg'
        />
      </Stack>
    </Link>
  )
}