import { createContext, MutableRefObject, ReactNode, useContext, useEffect, useRef, useState } from 'react'
import { AssetPreview } from '../../../../../@types/asset.ts'

type ActivePreviewContextType = {
  activePreviewId: string | null;
  scrollToPreviewInRightPane: (assetPreviewId: string) => void
  leftSectionRef: MutableRefObject<HTMLElement | null>
  rightPanePreviewRefs: MutableRefObject<PreviewRefHolder[]>
  leftPanePreviewRefs: MutableRefObject<PreviewRefHolder[]>
}

type PreviewRefHolder = {
  preview: AssetPreview,
  el: HTMLElement
}

const ActivePreviewContext = createContext<ActivePreviewContextType>({} as ActivePreviewContextType)

export const ActivePreviewProvider = ({ children } : {children: ReactNode}) => {
  const [activePreviewId, setActivePreviewId] = useState<string | null>(null)
  const rightPanePreviewRefs = useRef<PreviewRefHolder[]>([])
  const leftPanePreviewRefs = useRef<PreviewRefHolder[]>([])
  const rightSectionEl = document.body
  const leftSectionRef = useRef<HTMLElement | null>(null)

  const handleRightPaneScroll = () => {
    if (rightSectionEl) {
      const activeThreshold = window.innerHeight * 2/5

      const selected = rightPanePreviewRefs.current.find(holder => {
        const top = holder.el.getBoundingClientRect().top
        return top > 0 && top <= activeThreshold
      })

      if(selected){
        setActivePreviewId(selected.preview.assetPreviewId)
        scrollPreviewIntoLeftPaneIfNeeded(selected.preview.assetPreviewId)
      }
    }
  };

  const scrollToPreviewInRightPane = (assetPreviewId: string) => {
    const target = rightPanePreviewRefs.current.find(it => it.preview.assetPreviewId == assetPreviewId)
    const targetElement = target?.el
    if (targetElement && rightSectionEl) {
      const topPosition = targetElement.getBoundingClientRect().top + rightSectionEl.scrollTop - 64 - 64;
      rightSectionEl.scrollTo({
        top: topPosition,
        behavior: 'smooth'
      })
    }
  };

  const scrollPreviewIntoLeftPaneIfNeeded = (assetPreviewId: string) => {
    const target = leftPanePreviewRefs.current.find(it => it.preview.assetPreviewId == assetPreviewId)
    const targetElement = target?.el
    if (targetElement && leftSectionRef.current) {
      const rect = targetElement.getBoundingClientRect()
      const isOffBottom = rect.bottom >= window.innerHeight
      const isOffTop = rect.top <= 0
      if(isOffBottom){
        const bottomPosition = rect.bottom + leftSectionRef.current.scrollTop - window.innerHeight + 64
        leftSectionRef.current.scrollTop = bottomPosition
      } else if(isOffTop){
        const topPosition = rect.top + leftSectionRef.current.scrollTop - 64 - 64
        leftSectionRef.current.scrollTop = topPosition
      }
    }
  };

  // Attach the scroll listener to the right section
  useEffect(() => {
    if (rightSectionEl) {
      rightSectionEl.addEventListener('scroll', handleRightPaneScroll);
      return () => rightSectionEl.removeEventListener('scroll', handleRightPaneScroll);
    }
  }, [rightSectionEl]);

  const defaultActivePreviewId = rightPanePreviewRefs.current[0]?.preview.assetPreviewId
  return (
    <ActivePreviewContext.Provider value={{
      activePreviewId: activePreviewId || defaultActivePreviewId,
      scrollToPreviewInRightPane,
      rightPanePreviewRefs,
      leftPanePreviewRefs,
      leftSectionRef
    }}>
      {children}
    </ActivePreviewContext.Provider>

  )
}

export function useActivePreviewContext() {
  return useContext(ActivePreviewContext)
}