import { Box } from '@mui/material'
import { Outlet } from 'react-router-dom'
import { useHostedContentRoomBootstrap } from '../../hooks/useHostedContentRoom'
import LoadingScreen from '../../components/LoadingScreen'
import { useParams } from 'react-router'
import { ContentRoomThemeProvider } from '../../components/hosted-content-room/ContentRoomThemeProvider'
import { useTheme } from '@mui/material/styles'
import { HostedContentRoomProvider } from '../../contexts/HostedContentRoomContext.tsx'
import { HostedContentRoomAppBarProvider } from '../../contexts/HostedContentRoomAppBarContext.tsx'
import { HostedContentRoomAppBar } from '../../components/hosted-content-room/HostedContentRoomAppBar.tsx'

export function HostedContentRoomLayout() {
  const { shortCode } = useParams()
  const { data: contentRoom } = useHostedContentRoomBootstrap(shortCode)

  if (!contentRoom?.customization) {
    return <LoadingScreen />
  }

  return (
    <ContentRoomThemeProvider palette={contentRoom.customization.palette}>
      <HostedContentRoomProvider room={contentRoom}>
        <HostedContentRoomAppBarProvider>
          <HostedContentRoomAppBar />
          <LayoutContent />
        </HostedContentRoomAppBarProvider>
      </HostedContentRoomProvider>
    </ContentRoomThemeProvider>
  )
}

function LayoutContent() {
  const theme = useTheme()
  return (
    <Box sx={{ background: theme.palette.background.default }}>
      <Outlet />
    </Box>
  )
}

